import {
  FUNNEL_URL,
  ML_APPLY_URL_DEV,
  ML_APPLY_URL_QA,
  ML_APPLY_URL,
  SF_API_TIMEOUT,
  SF_API_TIMEOUT_NON_PROD
} from './constants';

let env = process.env.GATSBY_TEALIUM_ENV || 'dev';
/**
 * Defaults for the following variables are set to the dev environment's value.
 * This allows instances not built by the build kite pipeline, such as local dev instances,
 * to connect to an instance of sales force and the sales force API Proxy.
 *
 * These defaults will be superceded by any env.variables set in the running machine. This can
 * be done on your local development machines in the same way it can in the buildkite piepline.
 *
 * Please see /.buildkite/pipeline.yml for how theses defaults are overridden during build and deployment.
 * Put simply, the buildkite pipeline has steps based on the current branch name. There is a step
 * for each of: master; preprod; test01; and 'other' (!master !prepro !test01). Each of these steps are used to set environment
 * variables to different values - the different but correct values for each of preprod, master,
 * test01 or 'other' - where 'other' are any staging branches
 */
const SALESFORCE_BROKER_DASHBOARD_URL = process.env.GATSBY_SALESFORCE_BROKER_DASHBOARD_URL || 'https://origdev-gecapitalau.cs74.force.com/latitudebrokers/s/login/';
const SALESFORCE_BASE_URL = process.env.GATSBY_SALESFORCE_BASE_URL || 'https://gecapitalanz--OrigQA.my.salesforce.com/';
const LFS_ADDRESS_TOKEN_BASE_URL = process.env.GATSBY_LFS_ADDRESS_TOKEN_BASE_URL || `https://api.dev.latitudefinancial.com/v1/address-token-generator`;
const LFS_SOFT_QUOTE_PROXY_BASE_URL = process.env.GATSBY_LFS_SOFT_QUOTE_PROXY_BASE_URL || 'https://api.dev.latitudefinancial.com/v1/soft-quote-proxy';
const DATATOOLS_BASE_URL = process.env.GATSBY_DATATOOLS_BASE_URL || `https://kleber.datatoolscloud.net.au/KleberWebService/DtKleberService.svc`;
const SOFT_QUOTE_BASE_URL = process.env.GATSBY_SOFT_QUOTE_BASE_URL || 'https://test01.staging.quote-latitudefinancial.digitalweb-np.lfscnp.com';
const FUNNEL_URL_PL_HOST = process.env.GATSBY_FUNNEL_URL_PL_HOST || `https://origdev-gecapitalau.cs74.force.com`;
const LFS_WEBSITE_URL = process.env.GATSBY_LFS_WEBSITE_URL || `https://pl-softquote-test01.staging.latitudefinancial.digitalweb-np.lfscnp.com`;
const GEM_WEBSITE_URL = process.env.GATSBY_GEM_WEBSITE_URL || `https://pl-softquote-test01.staging.gemfinance.digitalweb-np.lfscnp.com`;
/**
 * The definition of the URLS constant below performs the same task as the definitions in `/.buildkite/pipeline.yml` such as
 `- 'GATSBY_LFS_SOFT_QUOTE_PROXY_BASE_URL=https://api.latitudefinancial.com/v1/soft-quote-proxy'`
 * That task is to define the constant values differently for different environments.
 *
 * The constants defined in this `const URLS` are returned by the getUrlFromEnv function.
 * This means that there to determine the values of env variables you need to edit either the values in this file,
 * or in the pipeline.yml file for values defined using code starting with `process.env.GATSBY_...`
 *
 * Which of the values defined here is returned by the `getUrlFromEnv` function still does depend on values
 * in the pipeline.yml file in two ways: Firstly the GATSBY_TEALIUM_ENV environment variable defined in pipeline.yml
 * is used to determine which of the .dev, .qa or .prod key value pairs is chosen from the objects declared below.
 * As of November 2021, these values were set to `prod` for master branch only, `qa` for both preprod and test01 branches,
 * and `dev` for all other branches including staging branches such named after a Jira story such as lm-3564
 *
 * Secondly, the value of FUNNEL_URL.PL.QA is determined by the value of the line like
   `- 'GATSBY_FUNNEL_URL_PL_HOST=https://origqa-gecapitalau.cs5.force.com'`
 * in the pipeline.yml file. This is necessary to allow because both preprod and test01 environments use a
 * GATSBY_TEALIUM_ENV value of `qa`, however the test01 environment needs to use https://origqa-gecapitalau.cs5.force.com
 * whereas the preprod environment needs to use https://preprod-gecapitalau.cs31.force.com'.
 *
 * Prior to pullng this value from the pipeline.yml file, the value was hard coded in the constants file.
 * This meant the constants file needed to be edited between deployment to test01 and preprod. This editing was error prone,
 * and resulted in John Murfett's first deployment to preprod using the incorrect test01 value, as this editing step was missed.
 * Moving this value to the pipeline file makes it less likely similar errors will be made by future developers, as well as moving
 * closer to the ideal where the same code is used in every environment.
 */
const URLS = {
  'funnel-start': {
    dev: 'https://cx-dev04.lfs.corefinance.io/apply/',
    qa: 'https://cardapp-sandbox.latitudefinancial.com/apply/',
    prod: 'https://cardapp.latitudefinancial.com/apply/'
  },
  'funnel-start-motor-loan': {
    dev: ML_APPLY_URL_DEV,
    qa: ML_APPLY_URL_QA,
    prod: ML_APPLY_URL
  },
  'funnel-start-asset-loan': {
    dev: FUNNEL_URL.AL.DEV,
    qa: FUNNEL_URL.AL.QA,
    prod: FUNNEL_URL.AL.PROD
  },
  'funnel-start-soft-quote': {
    dev: FUNNEL_URL.SQ.DEV,
    qa: FUNNEL_URL.SQ.QA,
    prod: FUNNEL_URL.SQ.PROD
  },
  'funnel-start-kiwibank-soft-quote': {
    dev: FUNNEL_URL.SQ.DEV_KB,
    qa: FUNNEL_URL.SQ.QA_KB,
    prod: FUNNEL_URL.SQ.PROD_KB
  },
};

export function getUrlFromEnv(urlId, queryString = '') {
  switch (urlId) {
    case 'pl-apply-url-au':
      return `${LFS_WEBSITE_URL}/loans/apply${queryString}`;
    case 'pl-apply-url-gem':
      return `${GEM_WEBSITE_URL}/loans/apply${queryString}`;
    case 'funnel-pl-apply-now-button': // Note this option may not be in use - John Murfett left it in to minimise risk.
    case 'funnel-pl-start-application-button':
      return `${FUNNEL_URL_PL_HOST}/latitude${queryString}`;
    case 'pl-apply-url-broker':
        return `${FUNNEL_URL_PL_HOST}/latitude?form=broker&sqr=js${queryString}`;
    case 'funnel-home-personal-loan':
      return `${LFS_WEBSITE_URL}/loans/personal-loan${queryString}`;
    case 'funnel-home-personal-loan-broker':
      return `${LFS_WEBSITE_URL}/brokers${queryString}`
    case 'funnel-home-personal-loan-gem':
      return `${GEM_WEBSITE_URL}/loans/personal-loans${queryString}`;
    case 'pl-debt-con-au-url':
      return `${LFS_WEBSITE_URL}/loans/debt-consolidation-loans${queryString}`;
  }

  const url = URLS[urlId][env];

  if (url) {
    return url + queryString;
  }

  throw new Error(`${urlId} URL doesn't exist in ${env} environment`);
}

export function getSalesforceBaseUrl() {
  return SALESFORCE_BASE_URL;
}

export function getSalesforceBrokerUrl() {
  return SALESFORCE_BROKER_DASHBOARD_URL;
}

export function getLfsAddressTokenBaseUrl() {
  return LFS_ADDRESS_TOKEN_BASE_URL;
}

export function getLfsSoftQuoteProxyBaseUrl() {
  return LFS_SOFT_QUOTE_PROXY_BASE_URL;
}

export function getDatatoolsBaseUrl() {
  return DATATOOLS_BASE_URL;
}

export function getSoftQuoteBaseUrl() {
  return SOFT_QUOTE_BASE_URL;
}

export function getEnvForDebug() {
  return env !== 'prod';
}

export function getSFApiTimeout() {
  return env !== 'prod' ? SF_API_TIMEOUT_NON_PROD : SF_API_TIMEOUT;
}
